/*@ngInject*/
function JobChooseController(jobTracker, searchQuery, $scope, $window) {
  this.jobList = [];
  this.chosenJobs = [];
  this.jobs = {};
  this.multiple = false;
  let fuzzy;

  let monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  let dayNames = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];

  // $window.onkeydown = e => {
  //   if (e.shiftKey) {
  //     $scope.$applyAsync(() => {
  //       this.multiple = true;
  //     })
  //   }
  // };

  // $window.onkeyup = e => {
  //   console.info(e);
  //   if (e.key === 'Shift' || e.keyCode === 16 || e.which === 16 || e.code === 'ShiftLeft' || e.code === 'ShiftRight') {
  //     $scope.$applyAsync(() => {
  //       if (this.chosenJobs.length == 0) {
  //         this.multiple = false;
  //       }

  //     });
  //   }
  // };

  this.jobQueries = [];
  const _updateJobList = () => {
    jobTracker.initializeAsync().then(() => {
      this.jobs = jobTracker.jobs[this.jobType];

      this.jobList = Object.keys(this.jobs)
        .sort((a, b) => {
          return (
            new Date(parseInt(b.substr(0, 8), 16) * 1000) -
            new Date(parseInt(a.substr(0, 8), 16) * 1000)
          );
        })
        .map(val => {
          if (
            this.jobs[val].inputQueryConfig &&
            this.jobs[val].inputQueryConfig.queryBody
          ) {
            this.jobQueries.push(
              searchQuery.getQuery(this.jobs[val].inputQueryConfig.queryBody)
            );
          } else {
            this.jobQueries.push(null);
          }

          return this.jobs[val];
        });
    });
  };

  // Update the component any time the jobs list could change
  // TODO: simplify listeners
  const _setListener = () => {
    $scope.$on(jobTracker.jobUpdatedEvent, () => _updateJobList());
  };

  // this.update = (job) => {
  //   $location.search('_id', job._id);
  // };

  //TODO: use array returned from server, instead of an object that is then converted to an array
  //TODO:re-enable search
  this.$onInit = () => {
    _updateJobList();
    _setListener();
    // We want to store parent field names (i.e dbSNP) as well as fully resolved path names (i.e dbSNP.name)
    // fuzzy = new $window.Fuse(this.jobList, {
    //   shouldSort: true,
    //   threshold: 0.6,
    //   distance: 100,
    //   maxPatternLength: 32,
    //   minMatchCharLength: 2,
    //   keys : ['name', 'assembly', 'searchDate'],
    // });
  };

  /**
   * Search for states... use $timeout to simulate
   * remote dataservice call.
   */

  // this.querySearch = (query) => {
  //   if(query === '*' || query === "'" || query === '"' || query === "''" || query === "\"\"" || !query) {
  //     return this.jobList;
  //   }

  //   return fuzzy.search(query);
  // }
}

angular
  .module("sq.jobs.chooser.component", [
    "sq.jobs.tracker.service",
    "sq.jobs.events.service",
    "sq.jobs.results.search.query.service"
  ])
  .component("sqJobChooser", {
    bindings: {
      // The submission object (could be search or main job submission)
      cardWidth: "@",
      onUpdate: "&",
      failBanner: "<",
      jobType: "<"
    }, // isolate scope
    templateUrl: "jobs/jobs.chooser.tpl.html",
    controller: JobChooseController
  });
